import "script-loader!jquery";
import "script-loader!jquery-ujs";
import "script-loader!jquery-ui";
import "script-loader!jquery-ui/ui/unique-id.js";
import "script-loader!jquery-ui/ui/widgets/tabs.js";
import "script-loader!jstz";
import "script-loader!jqueryInput";
import "script-loader!jqueryAutocomplete";
import "script-loader!jqueryUiDatepicker";
import "script-loader!jquery-timepicker";
import "script-loader!raty-js";
import "script-loader!jQueryUITabs";
import tippy from "tippy.js";

(function () {
  "use strict";

  function initTippy() {
    var options = {
      duration: 500,
      allowHTML: true,
      interactive: true,
      placement: "auto",
    };
    tippy("[data-tippy-content]", options);
  }

  if (!document.addEventListener) {
    return;
  } // IE8
  initTippy();
})();
